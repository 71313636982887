import IntervalTree, { Interval as TreeInterval } from '@flatten-js/interval-tree';
import { Order } from '@local/web-design-system';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { format } from 'date-fns';
import { isEmpty, isEqual } from 'lodash-es';
import store from 'store';
import { v4 as uuidv4 } from 'uuid';
import {
    PARENT_SAMPLE,
    PARENT_SAMPLE_NAME,
    RANKED_RESULTS,
    SAMPLE_CONTROL_TYPE_COL_ID,
    SAMPLE_CONTROL_TYPE_ID,
    SAMPLE_NUMBER_COL_ID,
    SAMPLE_RESULTS,
    SAMPLE_INFO_COLUMNS,
    SAMPLE_TYPE_COL_ID,
    SAMPLE_TYPE_STATE,
    SAMPLE_STATUS,
    SAMPLE_TYPES,
    COORDINATES_ID,
    GRID_COLUMN_ID,
    ROW_DRAG,
    ROCK_TYPE_COL_ID,
    ROW_SPAN,
    PADDED_ROW_SPAN,
    PARENT_ROW_SPAN,
    OUT_OF_ORDER,
    LITHOLOGY_COMMON_GROUP_ID,
    LITHOLOGY_LINKED_GROUP_ID,
    PARENT_PADDED_ROW_SPAN,
    MAX_ROW_BUFFER,
    EPSILON_MULTIPLIER,
    ROW_NUMBER,
    ROW_NUMBER_GROUP,
    LITHOLOGY_MIN_FROM,
    LITHOLOGY_MAX_TO,
    SKIP_LITHOLOGY_ROW,
    LITHOLOGY_ACTIONS,
    LITHOLOGY_GROUP_ACTIONS,
    PARENT_SKIP_LITHOLOGY_ROW,
    INFO_COLUMN,
    BUFFER_PARENT_ID,
    ACTIVITY_TYPES,
    COLUMN_MIN_WIDTH,
    IMAGO_COLUMN_MIN_WIDTH,
    SAMPLE_TYPE_MIN_WIDTH,
    CACHED_SORT_OPTIONS_KEY,
    emptySampleTypeID,
    hardCodedColumns,
    LITHOLOGY_TABLE_ID,
} from 'state-domains/constants';
import {
    ActivityMap,
    Column,
    Columns,
    Drillhole,
    Header,
    HeaderTypesDict,
    Interval,
    LimitedColumns,
    List,
    Row,
    RowReferencesListPointers,
    Rows,
    Section,
    Table,
    TableView,
    TableViewSpec,
} from 'state-domains/domain';
import {
    LOAD_LITHOLOGY_ROW_REFERENCES,
    LOAD_RANKED_COLUMNS,
    LOAD_ROW_REFERENCES,
    LOAD_SAMPLES,
    LOAD_SAMPLE_RESULTS,
} from 'state-domains/types/actionTypes';
import { getColumnSpanning } from 'src/components/Drillhole/DataGrid/DataGrid.utils';
import { UserPortalState } from 'src/state';

import {
    CellData,
    DrillHoleReferencesColumnType,
    DrillHoleReferencesIntervalType,
    RowReference,
    Sample,
    DrillHoleTextTypeMulti,
    DrillholeState,
    SampleResults,
    SampleResultRow,
    RankedColumnResponse,
    LoadRowReferenceState,
    DataRecord,
} from './types';
import {
    validateIntervalErrorsFast,
    createValuesWithValidationErrors,
    validateDepthErrors,
    validateGapErrorsFast,
} from './validations';

let excludedFillDownColumns: string[] = [];

export const convertRowToRowReference = (
    id: string,
    row: Row,
    drillhole: Drillhole,
    tableView: TableView,
): RowReference => ({
    index: row.rank,
    tableView: tableView.id,
    collar: drillhole.id,
    activity: drillhole.id,
    project: drillhole.project,
    id,
    createdBy: row.createdBy ?? '',
    createdAt: row.createdAt ?? { date: 0 },
    updatedAt: row.updatedAt ?? { date: 0 },
    updatedBy: row.updatedBy ?? '',
    values: {},
    verrors: { values: [] },
    parent: {},
    isvalid: true,
    dataRecords: {
        [tableView.id]: {
            table: tableView.id,
            tableView: tableView.id,
            collar: drillhole.id,
            rowReference: id,
            values: row.values,
            errors: { values: [] },
            verrors: row.errors ?? { values: [] },
        },
    },
});

export const attachTempResultData = (
    oldRowReferenceObject: RowReference,
    rowReference: RowReference,
    resultCollections: { table: string; validData: boolean }[],
) => {
    for (const resultCollection of resultCollections) {
        if (
            resultCollection.validData &&
            oldRowReferenceObject?.dataRecords?.[resultCollection.table]
        ) {
            rowReference.dataRecords = {
                ...rowReference.dataRecords,
                [resultCollection.table]:
                    oldRowReferenceObject?.dataRecords[resultCollection.table],
            };
        }
    }
};

export const addResultToStateCollection = (
    actionName: string,
    processedResult: any,
    loadRowReferenceState: LoadRowReferenceState,
) => {
    const {
        sampleResultsTable,
        rankedResultsTable,
        rowReferences,
        rowReferencesListPointers,
        samples,
        lithologyIntervalTree,
        linkedToLithoTable,
    } = processedResult;
    switch (actionName) {
        case LOAD_SAMPLE_RESULTS:
            loadRowReferenceState.temporaryTables = {
                ...loadRowReferenceState.temporaryTables,
                ...sampleResultsTable,
            };
            break;
        case LOAD_RANKED_COLUMNS:
            loadRowReferenceState.temporaryTables = {
                ...loadRowReferenceState.temporaryTables,
                ...rankedResultsTable,
            };
            break;
        case LOAD_ROW_REFERENCES:
            loadRowReferenceState.rowReferences = rowReferences;

            loadRowReferenceState.rowReferencesListPointers = rowReferencesListPointers;
            break;
        case LOAD_SAMPLES:
            loadRowReferenceState.samples = samples;
            break;
        case LOAD_LITHOLOGY_ROW_REFERENCES:
            loadRowReferenceState.lithologyIntervalTree = lithologyIntervalTree;

            loadRowReferenceState.linkedToLithoTable = linkedToLithoTable;
            break;
        default:
            break;
    }
};

export function createDictionary<T>(items: T[]) {
    const dict: Record<string, T> = {};
    let counter = 0;
    for (const item of items) {
        dict[(item as any).id] = item;

        if (counter > 0) {
            (dict[(item as any).id] as any).prevItem = items[counter - 1];
        }
        if (counter < items.length - 1) {
            (dict[(item as any).id] as any).nextItem = items[counter + 1];
        }
        counter += 1;
    }
    return dict;
}

export const combineNewRows = (
    rowReferences: RowReference[],
    newRows: Record<string, RowReference>,
) => {
    if (!newRows || !Object.values(newRows).length) return rowReferences;

    rowReferences.forEach((x: RowReference) => {
        if (x.id in newRows) {
            delete newRows[x.id];
        }
    });

    return [...rowReferences, ...Object.values(newRows)].sort((a: RowReference, b: RowReference) =>
        a.index > b.index ? 1 : -1,
    );
};

export const processLoadedRowReferences = (
    state: DrillholeState,
    items: RowReference[],
    _allState?: UserPortalState,
    _tableView?: TableView,
    _sampleTable?: string,
    _lithologyTable?: string,
) => {
    const sortInPlaceByIndex = (rowReferences: RowReference[]) => {
        rowReferences?.sort((a: RowReference, b: RowReference) => (a.index > b.index ? 1 : -1));
    };

    const rowReferencesListPointers = { ...state.rowReferencesListPointers };
    sortInPlaceByIndex(items);
    if (items?.length > 0) {
        rowReferencesListPointers.firstRowReference = items[0];
        rowReferencesListPointers.lastRowReference = items[items.length - 1];
    }

    return {
        rowReferencesListPointers,
        rowReferences: items ? createDictionary<RowReference>(items) : {},
    };
};

export const processLoadedSamples = (
    _state: DrillholeState,
    items: Sample[],
    _allState: UserPortalState,
    _tableView: TableView,
    _sampleTable: string,
    _lithologyTable: string,
) => {
    const dict: Record<string, Sample> = {};

    for (const item of items) {
        dict[item.id] = item;
    }

    return {
        samples: dict,
    };
};

export const findSpecialTableByIdentifier = (
    tableView: TableView,
    tables: Record<string, Table>,
    columnIdentifier: string,
) => {
    let sampleTable = '';

    if (tableView.singleTable) return tables[tableView.singleTable];

    for (const tableId of Object.keys(tableView.tables ?? {})) {
        const table = tables[tableId];
        if (!table) {
            continue;
        }
        if (columnIdentifier in table.columns) {
            sampleTable = table.id;
            break;
        }
    }

    return tables[sampleTable];
};

export const appendTableViewToFront = (
    allState: any,
    tableView: TableView,
    tableToAppend: string,
    columnIdentifier: string,
    useAppendingIntervalType: boolean,
) => {
    const tableViewToAppend = allState.subscription.tableViews[tableToAppend];

    if (!tableViewToAppend) return;

    convertToTableView(
        allState.subscription.tables,
        tableView,
        useAppendingIntervalType ? tableViewToAppend.intervalType : '',
    );
    const tableObject = findSpecialTableByIdentifier(
        tableViewToAppend,
        allState.subscription.tables,
        columnIdentifier,
    );

    if (!tableObject) return;

    Object.values(tableView.tables ?? {}).forEach(
        (x) => (tableView.tables[x.id] = { ...x, index: (x.index ?? 0) + 1 }),
    );

    tableView.tables[tableObject.id] = {
        table: tableObject.id,
        index: 0,
        columns: Object.assign({}, ...Object.keys(tableObject.columns).map((x) => ({ [x]: true }))),
        isvalid: true,
        id: tableObject.id,
    };

    return tableObject;
};

export const processLoadedLithologyRowReferences = (
    _state: DrillholeState,
    items: RowReference[],
    allState: UserPortalState,
    tableView: TableView,
    _sampleTable: string,
    lithologyTable: string,
) => {
    const lithologyTableView: TableView = allState.subscription.tableViews[lithologyTable];

    if (
        !lithologyTableView ||
        lithologyTableView.intervalType !== DrillHoleReferencesIntervalType.INTERVAL
    ) {
        return {
            lithologyIntervalTree: null,
            linkedToLithoTable: '',
        };
    }

    const lithologyTableObject = appendTableViewToFront(
        allState,
        tableView,
        lithologyTable,
        ROCK_TYPE_COL_ID,
        false,
    );

    const lithologyIntervalTree = new IntervalTree();

    items.forEach((x: RowReference) => addIntervalIfValid(lithologyIntervalTree, x, true));

    return {
        lithologyIntervalTree,
        linkedToLithoTable: lithologyTableObject?.id,
    };
};

export const linkLithologyToRowReferences = (
    items: RowReference[],
    lithologyIntervalTree: IntervalTree,
    table: string,
    sampleTable: string,
    skipAllTempLithoRows: boolean,
    recordInfo: { collar: string; project: string; activity: string; tableView: string },
) => {
    let maxRowSpanCount = Number.MIN_SAFE_INTEGER;
    const { collar, project, activity, tableView } = recordInfo;
    const rowReferencesToSkip: Record<string, boolean> = {};
    const intialRowReferences: Record<string, RowReference> = {};
    const linkedRowReferences: Record<string, RowReference> = {};
    const linkedIntervals: Record<string, TreeInterval> = {};
    const traversedIntervals: Record<string, boolean> = {};
    const temporaryRows: string[] = [];

    const invalidValues = (
        from: number | null | undefined,
        to: number | null | undefined,
    ): boolean => (!from && from !== 0) || (!to && to !== 0) || from >= to;

    const lithoDataRecord = (
        id: string,
        additionalProperties: any,
        lithoRecord?: RowReference,
    ): DataRecord => ({
        errors: { values: [] },
        verrors: { values: [] },
        rowReference: id,
        tableView,
        table,
        collar,
        values: {
            ...(lithoRecord && {
                ...lithoRecord.dataRecords[table]?.values,
                'from-lithology': lithoRecord.values.from,
                'to-lithology': lithoRecord.values.to,
            }),
            ...additionalProperties,
        },
    });

    const temporaryLithoRow = (
        id: string,
        additionalProperties: any,
        lithoRecord?: RowReference,
    ): RowReference => ({
        id,
        values: {},
        collar,
        dataRecords: {
            [table]: lithoDataRecord(id, additionalProperties, lithoRecord),
        },
        createdBy: '',
        verrors: { values: [] },
        parent: null,
        updatedAt: { date: 0 },
        project,
        index: 0,
        tableView,
        activity,
        updatedBy: '',
        isvalid: false,
        createdAt: { date: 0 },
    });

    const constructRowReference =
        (
            iterateParameters: {
                count: number;
                parentId: string;
                minValue: number;
                maxValue: number;
                previousRow: string;
                bufferParentId: string;
            },
            id: string,
            rowFunction: Function,
            spanInfo: { span: string; parentSpan: string },
        ) =>
        (
            updateCollection: Record<string, RowReference>,
            constructLitho: boolean,
            value?: RowReference,
            lithoValue?: RowReference,
        ) => {
            let tempId = id;
            let { count, parentId, bufferParentId } = iterateParameters;
            const { minValue, maxValue, previousRow } = iterateParameters;

            if (count === MAX_ROW_BUFFER) {
                maxRowSpanCount = MAX_ROW_BUFFER;

                updateCollection[parentId].dataRecords[table].values[spanInfo.span] =
                    MAX_ROW_BUFFER;

                if (constructLitho) {
                    updateCollection[parentId].dataRecords[table].values = {
                        ...updateCollection[parentId].dataRecords[table].values,
                        [LITHOLOGY_MIN_FROM]: minValue,
                        [LITHOLOGY_MAX_TO]: maxValue,
                        ...(bufferParentId && {
                            [BUFFER_PARENT_ID]: bufferParentId,
                            [SKIP_LITHOLOGY_ROW]: skipAllTempLithoRows,
                        }),
                    };

                    if (!bufferParentId) {
                        temporaryRows.push(parentId);
                        bufferParentId = parentId;
                    }
                }

                count = 0;
                parentId = constructLitho ? uuidv4() : (value?.id ?? '');
            }

            if (constructLitho && count === 0) tempId = parentId;

            const item = rowFunction(
                tempId,
                {
                    ...(count !== 0 && { [spanInfo.parentSpan]: parentId }),
                    ...(constructLitho && {
                        [count === 0 ? PARENT_SKIP_LITHOLOGY_ROW : SKIP_LITHOLOGY_ROW]:
                            skipAllTempLithoRows,
                    }),
                },
                lithoValue,
            );

            if (constructLitho) {
                updateCollection[tempId] = item;

                if (previousRow) {
                    updateCollection[previousRow].nextItem = item;
                }
            } else {
                updateCollection[tempId] = value ?? ({} as RowReference);

                updateCollection[tempId].dataRecords[table] = item;
            }

            count += 1;

            return {
                count,
                parentId,
                minValue: Math.min(lithoValue?.values.from ?? 0, minValue),
                maxValue: Math.max(lithoValue?.values.to ?? 0, maxValue),
                previousRow: tempId,
                bufferParentId,
            };
        };

    const iterateLithologyRows = (currentInterval: TreeInterval, linkedInterval?: TreeInterval) => {
        let lithologyRowReferenceIterateParameters = {
            count: 0,
            parentId: uuidv4(),
            minValue: Number.MAX_SAFE_INTEGER,
            maxValue: Number.MIN_SAFE_INTEGER,
            previousRow: '',
            bufferParentId: '',
        };
        lithologyIntervalTree.forEach((key, value) => {
            const optionalLoopCondition = linkedInterval ? key.less_than(linkedInterval) : true;
            if (
                !traversedIntervals[`${value.values.from}-${value.values.to}`] &&
                currentInterval.less_than(key) &&
                optionalLoopCondition
            ) {
                lithologyRowReferenceIterateParameters = constructRowReference(
                    lithologyRowReferenceIterateParameters,
                    uuidv4(),
                    temporaryLithoRow,
                    { span: PADDED_ROW_SPAN, parentSpan: PARENT_PADDED_ROW_SPAN },
                )(linkedRowReferences, true, undefined, value);
                traversedIntervals[`${value.values.from}-${value.values.to}`] = true;
            }
        });

        if (lithologyRowReferenceIterateParameters.count > 0) {
            linkedRowReferences[lithologyRowReferenceIterateParameters.parentId].dataRecords[
                table
            ].values = {
                ...linkedRowReferences[lithologyRowReferenceIterateParameters.parentId].dataRecords[
                    table
                ].values,
                [PADDED_ROW_SPAN]: lithologyRowReferenceIterateParameters.count,
                ...(lithologyRowReferenceIterateParameters.bufferParentId && {
                    [BUFFER_PARENT_ID]: lithologyRowReferenceIterateParameters.bufferParentId,
                    [SKIP_LITHOLOGY_ROW]: skipAllTempLithoRows,
                }),
            };

            if (lithologyRowReferenceIterateParameters.count > 1) {
                linkedRowReferences[lithologyRowReferenceIterateParameters.parentId].dataRecords[
                    table
                ].values = {
                    ...linkedRowReferences[lithologyRowReferenceIterateParameters.parentId]
                        .dataRecords[table].values,
                    [LITHOLOGY_MIN_FROM]: lithologyRowReferenceIterateParameters.minValue,
                    [LITHOLOGY_MAX_TO]: lithologyRowReferenceIterateParameters.maxValue,
                };

                if (!lithologyRowReferenceIterateParameters.bufferParentId) {
                    temporaryRows.push(lithologyRowReferenceIterateParameters.parentId);
                }
            }
        }

        if (lithologyRowReferenceIterateParameters.bufferParentId) {
            linkedRowReferences[lithologyRowReferenceIterateParameters.bufferParentId].dataRecords[
                table
            ].values = {
                ...linkedRowReferences[lithologyRowReferenceIterateParameters.bufferParentId]
                    .dataRecords[table].values,
                [LITHOLOGY_MIN_FROM]: lithologyRowReferenceIterateParameters.minValue,
                [LITHOLOGY_MAX_TO]: lithologyRowReferenceIterateParameters.maxValue,
            };
        }

        maxRowSpanCount = Math.max(maxRowSpanCount, lithologyRowReferenceIterateParameters.count);
    };

    for (const item of items) {
        if (rowReferencesToSkip[item.id] || invalidValues(item?.values?.from, item?.values?.to)) {
            if (!rowReferencesToSkip[item.id]) {
                intialRowReferences[item.id] = item;
            }
            continue;
        }

        let lithologyRecords = null;
        if (typeof item.values.from === 'number' && typeof item.values.to === 'number') {
            lithologyRecords = lithologyIntervalTree.search(
                [item.values.from, item.values.to],
                (value, key) => ({ key, value }),
            );
        }

        if (!lithologyRecords?.length) {
            intialRowReferences[item.id] = item;
            continue;
        }

        const lithologyRecord = lithologyRecords[0];
        if (!(item.id in linkedIntervals)) {
            linkedIntervals[item.id] = lithologyRecord.key;
            traversedIntervals[
                `${lithologyRecord.value.values.from}-${lithologyRecord.value.values.to}`
            ] = true;
        }

        let currentItem = item;
        let rowReferenceIterateParameters = {
            count: 0,
            parentId: item.id,
            minValue: 0,
            maxValue: 0,
            previousRow: '',
            bufferParentId: '',
        };

        while (
            !!currentItem &&
            ((!invalidValues(currentItem?.values?.from, currentItem?.values?.to) &&
                new TreeInterval(currentItem?.values?.from, currentItem?.values?.to).intersect(
                    lithologyRecord.key,
                )) ||
                currentItem.dataRecords[sampleTable]?.values[SAMPLE_TYPE_COL_ID] ===
                    SAMPLE_CONTROL_TYPE_ID)
        ) {
            rowReferencesToSkip[currentItem.id] = true;
            rowReferenceIterateParameters = constructRowReference(
                rowReferenceIterateParameters,
                currentItem.id,
                lithoDataRecord,
                { span: ROW_SPAN, parentSpan: PARENT_ROW_SPAN },
            )(intialRowReferences, false, currentItem, lithologyRecord.value);
            currentItem = currentItem.nextItem;
        }

        if (rowReferenceIterateParameters.count > 1) {
            intialRowReferences[rowReferenceIterateParameters.parentId].dataRecords[table].values[
                ROW_SPAN
            ] = rowReferenceIterateParameters.count;
        }

        maxRowSpanCount = Math.max(maxRowSpanCount, rowReferenceIterateParameters.count);
    }

    let currentInterval: TreeInterval = new TreeInterval(
        Number.MIN_SAFE_INTEGER,
        Number.MIN_SAFE_INTEGER,
    );

    // Need to run through the items again to insert unused lithology intervals.
    for (const item of Object.values(intialRowReferences)) {
        if (!linkedIntervals[item.id]) {
            linkedRowReferences[item.id] = item;
            continue;
        }

        const linkedInterval = linkedIntervals[item.id];
        iterateLithologyRows(currentInterval, linkedInterval);
        currentInterval = linkedInterval;
        linkedRowReferences[item.id] = item;
    }

    // Add any additional lithology intervals to the end.
    iterateLithologyRows(currentInterval);

    return { linkedRowReferences, maxRowSpanCount, temporaryRows };
};

export const modifyColumnsForLinkedToLithology = (
    columnData: any,
    label: string,
    tableId: string,
    activity: ActivityMap,
    lithologyTable: string,
    isSample: boolean,
    isRowNumberPresent: boolean,
) => {
    const commonColumnProperties: ColDef = {
        lockPosition: true,
        minWidth: COLUMN_MIN_WIDTH,
        enableCellChangeFlash: false,
        suppressFiltersToolPanel: true,
        suppressPaste: true,
        tooltipComponentParams: {
            hideColumnSelect: true,
        },
    };

    const lithoActions: ColGroupDef = {
        headerName: '',
        marryChildren: true,
        suppressFiltersToolPanel: true,
        groupId: LITHOLOGY_GROUP_ACTIONS,
        children: [
            {
                lockPosition: 'left',
                pinned: 'left',
                colId: LITHOLOGY_ACTIONS,
                field: LITHOLOGY_ACTIONS,
                headerName: '',
                suppressFiltersToolPanel: true,
                enableCellChangeFlash: false,
                cellEditorParams: { visibleColumn: true },
                resizable: false,
                type: [INFO_COLUMN],
                initialWidth: COLUMN_MIN_WIDTH - 20,
                minWidth: COLUMN_MIN_WIDTH - 20,
            },
        ],
    };

    const lithoInterval: ColGroupDef = {
        headerName: `Interval - ${label}`,
        marryChildren: true,
        suppressFiltersToolPanel: true,
        groupId: LITHOLOGY_COMMON_GROUP_ID,
        children: [
            {
                ...commonColumnProperties,
                colId: 'from-lithology',
                field: 'from-lithology',
                headerName: 'From',
                type: ['numeric'],
                suppressFillHandle: true,
                initialWidth: COLUMN_MIN_WIDTH + 20,
                cellRendererParams: { alwaysReadOnly: true },
                cellEditorParams: { visibleColumn: true, linkedToLitho: true },
            },
            {
                ...commonColumnProperties,
                colId: 'to-lithology',
                field: 'to-lithology',
                initialWidth: COLUMN_MIN_WIDTH + 20,
                headerName: 'To',
                type: ['numeric'],
                suppressFillHandle: true,
                cellRendererParams: { alwaysReadOnly: true },
                cellEditorParams: { visibleColumn: true, linkedToLitho: true },
            },
        ],
    };

    const lithologyIndex = columnData.columns.findIndex(
        (x: ColGroupDef) => x.groupId === lithologyTable,
    );
    const lithologyElement: ColGroupDef = columnData.columns.splice(lithologyIndex, 1)[0];
    lithologyElement.groupId = LITHOLOGY_LINKED_GROUP_ID;
    lithologyElement.suppressFiltersToolPanel = true;
    const columnCount = columnData.columns.reduce(
        (accum: number, currentValue: ColGroupDef) => accum + currentValue.children.length,
        0,
    );

    lithologyElement.children = lithologyElement.children.map((x: ColDef) => ({
        ...x,
        hide:
            x.hide &&
            !Object.keys(
                activity?.lithologyListSpecs?.tableSpecs?.[tableId]?.listSpecs?.[x.colId ?? '']
                    ?.childrenLists ?? {},
            ).length,
        lockPosition: true,
        enableCellChangeFlash: false,
        suppressFillHandle: true,
        minWidth:
            x.type?.[0] === DrillHoleReferencesColumnType.IMAGO
                ? IMAGO_COLUMN_MIN_WIDTH
                : COLUMN_MIN_WIDTH,
        suppressFiltersToolPanel: true,
        suppressPaste: true,
        cellEditorParams: {
            ...x.cellEditorParams,
            linkedToLitho: true,
            linkedToLithoTableName: label,
        },
        cellRendererParams: {
            alwaysReadOnly: true,
        },
    }));

    const commonProperties = {
        pinned: false,
        lockPosition: true,
    };

    columnData.columns[0].children = columnData.columns[0].children.map(
        (x: ColDef, index: number) => ({
            ...x,
            ...commonProperties,
            ...(index === 0 && {
                colSpan: getColumnSpanning,
                cellEditorParams: { ...x.cellEditorParams, columnCount },
            }),
        }),
    );

    if (isSample) {
        columnData.columns[1].children = columnData.columns[1].children.map((x: ColDef) => ({
            ...x,
            ...commonProperties,
        }));
    }

    const lithologyColumns: ColGroupDef[] = [lithoInterval, lithologyElement];

    if (!isRowNumberPresent) lithologyColumns.unshift(lithoActions);

    columnData.columns.unshift(...lithologyColumns);
};

export const rowNumber: ColDef = {
    lockPosition: 'left',
    pinned: 'left',
    lockPinned: true,
    colId: ROW_NUMBER,
    field: ROW_NUMBER,
    headerName: 'Row Number',
    suppressColumnsToolPanel: true,
    enableCellChangeFlash: false,
    cellEditorParams: { visibleColumn: true },
    type: [INFO_COLUMN],
    maxWidth: 100,
    resizable: true,
    initialWidth: COLUMN_MIN_WIDTH - 20,
};

export const rowGroupNumber: ColGroupDef = {
    groupId: ROW_NUMBER_GROUP,
    headerName: 'Row Number Group',
    marryChildren: true,
    suppressColumnsToolPanel: true,
    children: [rowNumber],
};

export const addRowNumberColumn = (columnData: any) => {
    const isMultiTable = columnData.columns[0]?.groupId;

    columnData.excludedFillDownColumns.push(ROW_NUMBER);
    columnData.columns.unshift(isMultiTable ? rowGroupNumber : rowNumber);
};

export const compareIntervalsWithoutLength = (oldRow: any, newRow: any): boolean => {
    if ('length' in oldRow) {
        delete oldRow.length;
    }

    if ('length' in newRow) {
        delete newRow.length;
    }

    return JSON.stringify(oldRow) !== JSON.stringify(newRow);
};

export const processLoadedSampleRowReferences = (
    _state: DrillholeState,
    items: RowReference[],
    allState: UserPortalState,
    tableView: TableView,
    sampleTable: string,
    _lithologyTable: string,
) => {
    const sampleTableView: TableView = allState.subscription.tableViews[sampleTable];

    if (!sampleTableView) return;

    const sampleTableObject = appendTableViewToFront(
        allState,
        tableView,
        sampleTable,
        SAMPLE_NUMBER_COL_ID,
        true,
    );

    const dict: Record<string, any> = {};

    for (const item of items) {
        dict[item.sample ?? ''] = {
            dataRecords: item.dataRecords[sampleTableObject?.id ?? ''].values,
            values: item.values,
        };
    }

    return {
        sampleRowReferences: dict,
    };
};

const findLargestIndex = (collection: any[]) => {
    let index = 0;

    collection.forEach((x: any) => {
        if (x.index > index) index = x.index;
    });

    return index;
};

const convertToTableView = (
    tables: Record<string, Table>,
    tableView: TableView,
    intervalType: 'intervals' | 'depths' | 'data' | 'other' | '' = '',
) => {
    if (intervalType) {
        tableView.intervalType = intervalType;
    }

    if (tableView.singleTable) {
        const table: Table = tables[tableView.singleTable];

        tableView.singleTable = null;

        tableView.tables = {
            [table.id]: {
                id: table.id,
                index: 0,
                columns: Object.assign(
                    {},
                    ...Object.keys(table.columns).map((x: string) => ({ [x]: true })),
                ),
                table: table.id,
                isvalid: true,
            },
        };
    }
};

const modifyTableView = (tableView: TableView, newTable: Function, isSampleResults: boolean) => {
    let index;

    if (isSampleResults && tableView.tables[RANKED_RESULTS]) {
        index = tableView.tables[RANKED_RESULTS].index;

        tableView.tables[RANKED_RESULTS].index = index + 1;
    } else {
        index = findLargestIndex(Object.values(tableView?.tables ?? {})) + 1;
    }

    tableView.tables = {
        ...tableView.tables,
        ...newTable(index),
    };
};

export const processLoadedSampleResults = (
    _state: DrillholeState,
    items: SampleResults,
    allState: UserPortalState,
    tableView: TableView,
    _sampleTable: string,
    _lithologyTable: string,
) => {
    convertToTableView(allState.subscription.tables, tableView);

    const allColumns = items.columns.map((x, index) => ({
        id: x.id,
        type: SAMPLE_RESULTS,
        editable: false,
        required: false,
        name: `${x.name ? x.name : x.method} ${x.analyte ?? ''} (${x.unit})`,
        index,
        isvalid: !x.isRanked,
    }));

    const sampleResultRowReferences = Object.assign(
        {},
        ...items.samples.map((x: SampleResultRow) => {
            const valuesDict = Object.assign(
                {},
                ...Object.entries(x.values).map(([key, value]) => ({
                    [allColumns[key as unknown as number].id]: {
                        value,
                        releasedValues: x.releasedValues[allColumns[key as unknown as number].id],
                    },
                })),
            );

            return {
                [x.rowRefIndex]: { values: valuesDict },
            };
        }),
    );

    const validColumns = allColumns.filter((x) => x.isvalid);
    const tableName = 'Sample Results';
    const sampleResultColumns = Object.assign({}, ...validColumns.map((x) => ({ [x.id]: x })));

    const sampleResultsTableView = (index: number) => ({
        [SAMPLE_RESULTS]: {
            id: SAMPLE_RESULTS,
            table: SAMPLE_RESULTS,
            index,
            isvalid: true,
            columns: Object.assign(
                {},
                ...Object.keys(sampleResultColumns).map((x) => ({ [x]: true })),
            ),
        },
    });

    const sampleResultsTable = {
        [SAMPLE_RESULTS]: {
            name: tableName,
            label: tableName,
            columns: sampleResultColumns,
            id: SAMPLE_RESULTS,
        },
    };

    if (Object.keys(sampleResultColumns ?? {}).length > 0) {
        modifyTableView(tableView, sampleResultsTableView, true);
    }

    return {
        sampleResultRowReferences,
        sampleResultsTable,
    };
};

export const processLoadedRankedResults = (
    _state: DrillholeState,
    items: RankedColumnResponse,
    allState: UserPortalState,
    tableView: TableView,
    _sampleTable: string,
    _lithologyTable: string,
) => {
    convertToTableView(allState.subscription.tables, tableView);

    const tableName = 'Ranked Columns';
    const rankedColumns = Object.assign(
        {},
        ...Object.values(items.rankedColumnsMap).map((x, index) => ({
            [x.id]: {
                id: x.id,
                type: RANKED_RESULTS,
                editable: false,
                required: false,
                name: `${x.name} (${x.unit})`,
                index,
                isvalid: true,
                additionalColumnInfo: { candidateColumns: x.candidateColumns },
            },
        })),
    );

    const rankedResultsTable = {
        [RANKED_RESULTS]: {
            name: tableName,
            label: tableName,
            columns: rankedColumns,
            id: RANKED_RESULTS,
        },
    };

    const rankedResultsTableView = (index: number) => ({
        [RANKED_RESULTS]: {
            id: RANKED_RESULTS,
            table: RANKED_RESULTS,
            index,
            isvalid: true,
            columns: Object.assign({}, ...Object.keys(rankedColumns).map((x) => ({ [x]: true }))),
        },
    });

    if (Object.keys(rankedColumns).length > 0) {
        modifyTableView(tableView, rankedResultsTableView, false);
    }

    return {
        rankedResultsTable,
    };
};

const buildTableColumns = (
    tableView: TableView,
    tables: Record<string, Table>,
    activity: ActivityMap,
) => {
    const allDisplayedColumns: string[] = [];
    let tablesToConstruct: Record<
        string,
        {
            tableName: string;
            columns: Columns;
            isLithologyTableInLinkedToLithologyTableView?: boolean;
        }
    > = {};
    const sortedTables = Object.fromEntries(
        Object.entries(tableView.tables ?? {}).sort(([, a], [, b]) => a.index - b.index),
    );

    if (!tableView.singleTable) {
        let lithologyTableId = '';
        let isLinkedToLithologyTableView = false;
        for (const sortedTable of Object.values(sortedTables)) {
            const displayedColumns = Object.keys(sortedTable.columns);
            const table = tables[sortedTable.table];

            allDisplayedColumns.push(...displayedColumns);

            if (!table?.columns) continue;
            if (!lithologyTableId) {
                lithologyTableId =
                    table.id === LITHOLOGY_TABLE_ID || table.parent === LITHOLOGY_TABLE_ID
                        ? table.id
                        : '';
            }
            isLinkedToLithologyTableView = isLinkedToLithologyTableView || table.linkedToLithology;
            tablesToConstruct = {
                ...tablesToConstruct,
                [table.id]: { columns: table.columns, tableName: table.label },
            };
        }
        if (lithologyTableId && isLinkedToLithologyTableView) {
            tablesToConstruct[lithologyTableId].isLithologyTableInLinkedToLithologyTableView = true;
        }
    } else {
        const table = tables[tableView.singleTable];
        tablesToConstruct = { [table.id]: { columns: table.columns, tableName: table.name } };
        allDisplayedColumns.push(...Object.keys(table.columns));
    }

    return buildColumnDef(
        tableView.singleTable ?? tableView.id,
        tablesToConstruct,
        allDisplayedColumns,
        activity,
        !tableView.singleTable,
    );
};

export const linkColumnLists = (
    tableView: string,
    tableId: string,
    columnId: string,
    activity: ActivityMap,
) => {
    let additionalListColumnInfo = {};

    const determineTableViewSpecLocation = (tableViewId: string) => {
        let tableViewSpecs: TableViewSpec | undefined;

        switch (tableViewId) {
            case activity.samples:
                tableViewSpecs = activity.samplesListSpecs;
                break;
            case activity.lithology:
                tableViewSpecs = activity.lithologyListSpecs;
                break;
            case activity.survey:
                tableViewSpecs = activity.surveyListSpecs;
                break;
            default:
                tableViewSpecs = activity.tableViewsSpecs
                    ? activity.tableViewsSpecs[tableViewId]
                    : undefined;
                break;
        }

        return tableViewSpecs;
    };

    const tableViewSpecs = determineTableViewSpecLocation(tableView);

    if (tableViewSpecs?.tableSpecs?.[tableId]?.listSpecs?.[columnId]) {
        const listSpec = tableViewSpecs.tableSpecs[tableId].listSpecs[columnId];
        if (Object.keys(listSpec?.childrenLists ?? {}).length) {
            additionalListColumnInfo = {
                linkedChildren: listSpec.childrenLists,
            };
        }

        const activityColumnExcludes = listSpec?.activityColumnExcludes ?? {};

        additionalListColumnInfo = {
            ...additionalListColumnInfo,
            defaultRow: listSpec?.defaultRow,
            activityColumnExcludes,
        };

        if (listSpec?.parentList) {
            const parentList = listSpec.parentList;
            const parentListSpec = determineTableViewSpecLocation(parentList.tableView)?.tableSpecs[
                parentList.table
            ]?.listSpecs[parentList.column];

            if (parentListSpec?.childrenLists?.[columnId]) {
                additionalListColumnInfo = {
                    ...additionalListColumnInfo,
                    linkedColumn: parentList.column,
                };

                const parentRows = Object.values(parentListSpec.rows).filter(
                    (x) => !isEmpty(x?.childrenColumns?.[columnId]?.rows ?? {}),
                );
                const linkValues = Object.assign(
                    {},
                    ...parentRows.map((x) => ({
                        [x.row]: {
                            ...(x.childrenColumns?.[columnId]?.rows ?? {}),
                            defaultRow: x.childrenColumns?.[columnId]?.defaultRow,
                        },
                    })),
                );

                additionalListColumnInfo = {
                    ...additionalListColumnInfo,
                    linkValues,
                };
            }
        } else if (columnId === SAMPLE_CONTROL_TYPE_COL_ID) {
            additionalListColumnInfo = {
                ...additionalListColumnInfo,
                listValues: activity.sampling?.controlTypes ?? {},
            };
        } else {
            additionalListColumnInfo = {
                ...additionalListColumnInfo,
                listValues: Object.assign(
                    {},
                    ...Object.keys(listSpec?.rows ?? {}).map((x) => ({ [x]: true })),
                ),
            };
        }
    } else if (tableView.includes(COORDINATES_ID)) {
        const listSpec =
            columnId === GRID_COLUMN_ID
                ? activity.headerCoordSpecs
                : (activity.headerCoordListSpecs as any)?.[columnId];
        const activityColumnExcludes = listSpec?.activityColumnExcludes ?? {};
        additionalListColumnInfo = {
            listValues: Object.assign(
                {},
                ...Object.keys(listSpec.rows).map((x) => ({ [x]: true })),
            ),
            defaultRow: listSpec?.defaultRow ?? {},
            activityColumnExcludes,
        };
    }

    return additionalListColumnInfo;
};

const buildColumnDef = (
    tableView: string,
    tablesToConstruct: Record<
        string,
        {
            tableName: string;
            columns: Columns;
            isLithologyTableInLinkedToLithologyTableView?: boolean;
        }
    >,
    displayedColumns: string[],
    activity: ActivityMap,
    isTableView: boolean,
) => {
    const columnData: any[] = [];
    const { columnAssociations } = activity;
    Object.entries(tablesToConstruct).forEach(([tableKey, tableInfo]) => {
        const columnDefs: ColDef[] = [];
        const sortedColumns = Object.fromEntries(
            Object.entries(tableInfo.columns).sort(([, a], [, b]) => a.index - b.index),
        );

        Object.entries(sortedColumns).forEach(([colKey, column]) => {
            let additionalListColumnInfo = {};

            if (column.type === DrillHoleReferencesColumnType.LIST) {
                additionalListColumnInfo = linkColumnLists(tableView, tableKey, colKey, activity);

                if (colKey === SAMPLE_TYPE_COL_ID) {
                    additionalListColumnInfo = {
                        ...additionalListColumnInfo,
                        sampleTypes: activity.samplesTypeSpecs,
                        showControlSample: !isEmpty(activity.sampling?.controlTypes),
                    };
                } else if (colKey === SAMPLE_CONTROL_TYPE_COL_ID) {
                    additionalListColumnInfo = {
                        ...additionalListColumnInfo,
                        controlTypes: activity.sampling?.controlTypes,
                    };
                }
            }

            let isAssociatedToOtherColumn = false;
            let isAnAssociatedColumn = false;
            if (columnAssociations && tableView in columnAssociations) {
                isAnAssociatedColumn = colKey === columnAssociations[tableView].column;
                isAssociatedToOtherColumn =
                    !(colKey in columnAssociations[tableView].commonColumns) &&
                    !isAnAssociatedColumn;
            }

            columnDefs.push(
                getColDef(
                    colKey,
                    column,
                    additionalListColumnInfo,
                    displayedColumns.includes(colKey),
                    { isAssociatedToOtherColumn, isAnAssociatedColumn },
                    tableInfo,
                ),
                ...(colKey === SAMPLE_CONTROL_TYPE_COL_ID
                    ? [
                          getColDef(
                              PARENT_SAMPLE,
                              {
                                  type: PARENT_SAMPLE,
                                  name: PARENT_SAMPLE_NAME,
                                  required: false,
                                  textType: '',
                              } as unknown as Column,
                              {},
                              true,
                              { isAssociatedToOtherColumn, isAnAssociatedColumn },
                          ),
                      ]
                    : []),
            );
        });

        if (isTableView) {
            columnData.push({
                headerName: tableInfo.tableName,
                groupId: tableKey,
                marryChildren: true,
                children: columnDefs,
            });
        } else {
            columnData.push(...columnDefs);
        }
    });

    return columnData;
};

export const comparator = (left: any, right: any) => {
    if (left === right) {
        // If value are same return straight away
        return true;
    }
    // Values are not equal we may have objects
    const x = {};
    const leftSide = left ?? x;
    const rightSide = right ?? x;

    if (Array.isArray(leftSide)) {
        const leftIds = leftSide.map((x: any) => x.uid);
        const rightIds = rightSide.map((x: any) => x.uid);

        return leftIds.length === rightIds.length && leftIds.every((x) => rightIds.includes(x));
    }
    if (typeof leftSide === 'object') {
        if ('date' in leftSide) {
            return leftSide.date === rightSide.date;
        }
        if ('text' in leftSide) {
            return leftSide.colour === rightSide.colour && leftSide.text === rightSide.text;
        }
        if ('id' in leftSide) {
            let result = leftSide.id === rightSide.id;

            if (result && (leftSide.childSamples || rightSide.childSamples)) {
                result = isEqual(leftSide.childSamples, rightSide.childSamples);
            }

            return result;
        }
        return false;
    }
    return false;
};

const getColDef = (
    key: string,
    value: Column,
    additionalListColumnInfo: any,
    visibleColumn: boolean,
    colAssociation: { isAssociatedToOtherColumn: boolean; isAnAssociatedColumn: boolean },
    tableInfo?: {
        tableName: string;
        columns: Columns;
        isLithologyTableInLinkedToLithologyTableView?: boolean;
    },
) => {
    const { isAssociatedToOtherColumn, isAnAssociatedColumn } = colAssociation;
    const suppressPasteTypes = new Set([
        ROW_DRAG,
        SAMPLE_INFO_COLUMNS,
        INFO_COLUMN,
        PARENT_SAMPLE,
        SAMPLE_RESULTS,
        RANKED_RESULTS,
        DrillHoleReferencesColumnType.ASSOCIATED_COLUMN,
        DrillHoleReferencesColumnType.CHECKBOX,
        DrillHoleReferencesColumnType.FILE,
        DrillHoleReferencesColumnType.IMAGO,
    ]);
    let type = value.type;
    const suppressFillHandle =
        !!value.calculation ||
        type === DrillHoleReferencesColumnType.FILE ||
        type === DrillHoleReferencesColumnType.IMAGO ||
        type === ROW_DRAG ||
        type === RANKED_RESULTS ||
        type === SAMPLE_RESULTS ||
        type === PARENT_SAMPLE ||
        isAssociatedToOtherColumn ||
        isAnAssociatedColumn ||
        hardCodedColumns.has(key);
    if (suppressFillHandle) {
        excludedFillDownColumns.push(key);
    }
    if (
        value.type === DrillHoleReferencesColumnType.TEXT &&
        value.textType === DrillHoleTextTypeMulti
    ) {
        type = DrillHoleReferencesColumnType.TEXTMULTI;
    } else if (isAnAssociatedColumn) {
        type = DrillHoleReferencesColumnType.ASSOCIATED_COLUMN;
    }
    const required = (value.required || key === SAMPLE_CONTROL_TYPE_COL_ID) ?? false;
    const headerName = required ? `${value.name} *` : value.name;

    let minWidth = COLUMN_MIN_WIDTH;
    let initialWidth = COLUMN_MIN_WIDTH + 100;

    if (type === DrillHoleReferencesColumnType.IMAGO) {
        minWidth = IMAGO_COLUMN_MIN_WIDTH;
    } else if (key === SAMPLE_TYPE_COL_ID) {
        minWidth = SAMPLE_TYPE_MIN_WIDTH;
    }

    if (
        type === DrillHoleReferencesColumnType.IMAGO ||
        type === DrillHoleReferencesColumnType.FILE ||
        type === DrillHoleReferencesColumnType.NUMERIC
    ) {
        initialWidth = COLUMN_MIN_WIDTH + 40;
    }

    const forcedVisibleColumn =
        isAnAssociatedColumn ||
        additionalListColumnInfo.linkedChildren ||
        key === ROCK_TYPE_COL_ID ||
        (required && !tableInfo?.isLithologyTableInLinkedToLithologyTableView);

    return {
        colId: key,
        field: key,
        headerName,
        minWidth,
        initialWidth,
        equals: comparator,
        cellEditorParams: {
            ...(value.type === DrillHoleReferencesColumnType.TEXT && {
                length: value.length,
            }), // If Text type multi add length
            ...(value.type === DrillHoleReferencesColumnType.LIST && {
                listId: value.list,
                ...additionalListColumnInfo,
            }), // If List add List ID
            ...(value.type === DrillHoleReferencesColumnType.NUMERIC && {
                precision: value.precision,
            }),
            ...(value.type === RANKED_RESULTS && value.additionalColumnInfo),
            ...(value.type === DrillHoleReferencesColumnType.IMAGO && value.imago),
            requiredColumn: required,
            visibleColumn,
            calculatedColumn: !!value.calculation || hardCodedColumns.has(key),
            isAssociatedToOtherColumn,
        },
        cellRendererParams: {
            ...(value.type === DrillHoleReferencesColumnType.NUMERIC && {
                precision: value.precision,
            }),
        },
        hide: (!visibleColumn || value.hidden === true) && !forcedVisibleColumn,
        type: [type],
        suppressFillHandle,
        tooltipComponentParams: {
            hideColumnSelect: forcedVisibleColumn,
        },
        suppressPaste:
            suppressPasteTypes.has(type) || !!value.calculation || isAssociatedToOtherColumn,
        headerComponentParams: {
            // anything defined here will be under props in ColumnHeader
            linkedColumn: additionalListColumnInfo.linkedColumn,
            isAnAssociatedColumn,
        },
    };
};

const sortListColumns = (listColumns: LimitedColumns) => {
    const sortedListColumns = Object.fromEntries(
        Object.entries(listColumns).sort(([, a], [, b]) => a.index - b.index),
    );

    const listColumnData: ColDef[] = [];
    listColumnData.push({ headerName: '', field: 'colour', colId: 'colour' });

    for (const [, value] of Object.entries(sortedListColumns)) {
        const colDef = {
            field: value.name,
            headerName: value.name,
            colId: value.id,
            type: value.type,
            cellEditorParams: { ...(value.list && { listId: value.list }) },
        } as ColDef;
        if (value.type === DrillHoleReferencesColumnType.DATE) {
            // For date type columns return formatted date
            colDef.valueFormatter = (params) =>
                params.value?.date ? format(new Date(params.value.date), 'MMM dd, yyyy') : '';
            colDef.type = value.type;
        }
        listColumnData.push(colDef);
    }
    return listColumnData;
};

const sortAndFilterListRows = (rows: Rows, reducedListOptions: any, useListOrder: boolean) => {
    let sortedRows = useListOrder
        ? Object.values(rows).sort((a, b) => a.rank - b.rank)
        : Object.keys(reducedListOptions ?? {}).map((x) => rows[x]);

    if (useListOrder && Object.keys(reducedListOptions ?? {}).length > 0) {
        sortedRows = sortedRows.filter((x) => Object.keys(reducedListOptions).includes(x.id));
    }

    return sortedRows;
};

export const createListRow = (
    row: Row,
    cols: LimitedColumns,
    primaryColumn: string,
    emptyObject?: object,
) => {
    if (Object.keys(row ?? {}).length === 0) return emptyObject ?? {};
    const rowFields: any = { colour: row.colour ?? '', id: row.id || uuidv4() };
    for (const [key, value] of Object.entries(row.values)) {
        const column = cols[key];

        if (column) {
            // List column keys needed. Used in List Editor when called from
            // Header or Associated Column
            rowFields[column.name] = value;
            rowFields[key] = new CellData(value);
            if (key === primaryColumn) {
                rowFields.primary = value;
            }
        }
    }

    return rowFields;
};

export const createListCollection = (
    list: List,
    reducedListOptions: any = null,
    createRows = true,
    useListOrder = true,
) => {
    const rowData = [];

    if (!list.columns || Object.keys(list.columns).length === 0) {
        return {
            rowData: [],
            columnData: [],
        };
    }

    const columnData: ColDef[] = sortListColumns(list.columns);
    let rows: Row[] = [];

    if (createRows) {
        rows = sortAndFilterListRows(list.rows, reducedListOptions, useListOrder);

        for (const rowInfo of rows) {
            if (!rowInfo.values[list.inuseColumn]) continue;

            rowData.push(createListRow(rowInfo, list.columns, list.primaryColumn));
        }
    }

    return {
        rowData,
        columnData,
    };
};

const DEFAULT_HEADER = '__DEFAULT__';
const sortSections = (sections: Section[]) => {
    if (sections.length > 0) {
        const sectionsMinusDefault = sections.filter((item) => item.name !== DEFAULT_HEADER);
        const sortedSectionsMinusDefault = [...sectionsMinusDefault].sort(
            (a, b) => a.index - b.index,
        );
        const defaultSection = sections.find((s) => s.name === DEFAULT_HEADER);
        return [defaultSection, ...sortedSectionsMinusDefault];
    }

    return [];
};

export const createHeaderCollection = (header: Header, headerTypes: HeaderTypesDict): Header => {
    const sectionsArray = header.sections
        ? Object.keys(header.sections).map((key) => header.sections[key])
        : [];
    const sortedSections = sortSections(sectionsArray);
    sortedSections.forEach((section: Section | undefined) => {
        if (section) {
            const fieldsArray = section.fields
                ? Object.keys(section.fields)
                      .map((key) => section.fields[key])
                      .sort((a, b) => a.index - b.index)
                : [];
            const fields = [];
            for (const f of fieldsArray) {
                const fieldDefinition = headerTypes[f.field];
                fields.push({ ...fieldDefinition, fieldId: f.id });
            }

            section.fieldsArray = fields;
        }
    });
    return { ...header, sectionsArray: sortedSections };
};

export const constructTableColumns = (
    tables: Record<string, Table>,
    tableView: TableView | null,
    activity: ActivityMap,
    isSample: boolean,
) => {
    const columns: any = [];
    const { calculatedColumns: { length: hasLength } = {} } = tableView ?? ({} as TableView);
    const emptyDataObject = {
        columns: [],
        excludedFillDownColumns: [],
    };

    excludedFillDownColumns = [];

    if (!tableView || Object.keys(tables).length === 0) {
        return emptyDataObject;
    }

    const defaultColumnProperties: ColDef = {
        lockPosition: 'left',
        pinned: 'left',
        lockPinned: true,
        type: ['numeric'],
        minWidth: COLUMN_MIN_WIDTH,
        tooltipComponentParams: {
            hideColumnSelect: true,
        },
    };

    const defaultGroupColumnProperties = {
        headerName: 'Interval',
        groupId: 'common',
        marryChildren: true,
        children: [],
    };

    if (isSample) {
        const defaultSampleStatusColumnProperties: ColDef = {
            lockPosition: 'left',
            pinned: 'left',
            resizable: false,
            maxWidth: 32,
            type: [SAMPLE_INFO_COLUMNS],
            field: '',
            headerName: '',
        };

        excludedFillDownColumns.push(SAMPLE_STATUS, SAMPLE_TYPE_STATE);
        const children = [
            {
                ...defaultSampleStatusColumnProperties,
                colId: SAMPLE_TYPE_STATE,
                field: SAMPLE_TYPE_STATE,
                headerName: 'Sample Type State',
            },
            {
                ...defaultSampleStatusColumnProperties,
                colId: SAMPLE_STATUS,
                field: SAMPLE_STATUS,
                headerName: 'Sample Status',
            },
        ];

        columns.push(
            ...(!isEmpty(tableView.tables)
                ? [
                      {
                          headerName: 'Sample Info',
                          groupId: SAMPLE_INFO_COLUMNS,
                          marryChildren: true,
                          suppressColumnsToolPanel: true,

                          children,
                      },
                  ]
                : children),
        );
    }
    if (tableView.id.includes(COORDINATES_ID)) {
        columns.push({
            type: [ROW_DRAG],
            cellEditorParams: { visibleColumn: true },
        });
    }

    let defaultColumns = [];
    switch (tableView.intervalType) {
        case String(DrillHoleReferencesIntervalType.INTERVAL):
            defaultColumns = [
                {
                    ...defaultColumnProperties,
                    colId: 'from',
                    field: 'from',
                    headerName: 'From',
                    initialWidth: COLUMN_MIN_WIDTH + 20,
                },
                {
                    ...defaultColumnProperties,
                    colId: 'to',
                    field: 'to',
                    headerName: 'To',
                    initialWidth: COLUMN_MIN_WIDTH + 20,
                },
                ...(hasLength
                    ? [
                          {
                              ...defaultColumnProperties,
                              colId: 'length',
                              field: 'length',
                              headerName: 'Length',
                              suppressFillHandle: true,
                              editable: false,
                              initialWidth: COLUMN_MIN_WIDTH + 20,
                              suppressPaste: true,
                          },
                      ]
                    : []),
            ];
            excludedFillDownColumns.push('length');
            if (tableView.singleTable) {
                columns.push(...defaultColumns);
            } else {
                columns.push({ ...defaultGroupColumnProperties, children: defaultColumns });
            }
            break;
        case String(DrillHoleReferencesIntervalType.DEPTH):
            defaultColumns = [
                {
                    ...defaultColumnProperties,
                    colId: 'depth',
                    field: 'depth',
                    headerName: 'Depth',
                    suppressFillHandle: true,
                    initialWidth: COLUMN_MIN_WIDTH + 20,
                },
            ];

            if (tableView.singleTable) {
                columns.push(...defaultColumns);
            } else {
                columns.push({ ...defaultGroupColumnProperties, children: defaultColumns });
            }
            break;
        default:
            break;
    }

    const tableColumns = buildTableColumns(tableView, tables, activity);

    columns.push(...tableColumns);

    if (String(DrillHoleReferencesIntervalType.DEPTH) === tableView.intervalType) {
        excludedFillDownColumns.push('depth');
    }

    return {
        columns,
        excludedFillDownColumns,
    };
};

export const adjustListPointers = (
    rowReference: RowReference,
    rowReferencesListPointers: RowReferencesListPointers,
) => {
    // Adjust pointer to point to new object
    if (rowReference.prevItem) {
        rowReference.prevItem.nextItem = rowReference;
    } else {
        rowReferencesListPointers.firstRowReference = rowReference;
    }
    if (rowReference.nextItem) {
        rowReference.nextItem.prevItem = rowReference;
    } else {
        rowReferencesListPointers.lastRowReference = rowReference;
    }
};

export const getPreviousRowReference = (
    rowReferencesListPointers: RowReferencesListPointers,
    index: number,
) => {
    let rowReference;
    let item = rowReferencesListPointers.firstRowReference;
    while (item) {
        if (item.index > index) {
            return rowReference;
        }
        rowReference = item;
        item = item.nextItem;
    }
    return rowReference;
};

export const attachLengthToInterval = (interval: Interval) => {
    let length: number | null = null;
    if (
        interval.from?.value !== undefined &&
        interval.from?.value !== null &&
        String(interval.from.value).trim() !== '' &&
        interval.to?.value !== undefined &&
        interval.to?.value !== null &&
        String(interval.to.value).trim() !== ''
    ) {
        length = Math.abs(interval.to.value - interval.from.value);
        length = Math.round((length + Number.EPSILON) * 10000) / 10000; // Round to max 4 places
    }

    interval.length = new CellData(length, []);
};

const getCurrentValuesWithErrors = (
    rowReference: RowReference,
    modifiedValues: Record<string, CellData>,
    isInterval: boolean,
) =>
    rowReference.id.includes('-')
        ? modifiedValues
        : createValuesWithValidationErrors(modifiedValues, isInterval);

export const createRowReferencesObjectForNewRow = (
    rowReferencesListPointers: RowReferencesListPointers,
    rowReference: RowReference,
    index: number,
    linkedToLithoTable: boolean,
) => {
    // New row
    const rowReferenceObject: Record<string, RowReference> = {};
    const firstRowReference = rowReferencesListPointers.firstRowReference;
    const prevRowReference = getPreviousRowReference(rowReferencesListPointers, index);
    if (prevRowReference) {
        // Adjust pointers

        rowReference.prevItem = prevRowReference;

        rowReference.nextItem = prevRowReference.nextItem;
        if (!prevRowReference.nextItem) {
            rowReferencesListPointers.lastRowReference = rowReference;
        }
        prevRowReference.nextItem = rowReference;
    } else {
        // Inserting row at the beginning

        rowReference.nextItem = firstRowReference;
        if (firstRowReference) {
            firstRowReference.prevItem = rowReference;

            rowReferencesListPointers.firstRowReference = rowReference;
        } else {
            rowReferencesListPointers.firstRowReference = rowReference;

            rowReferencesListPointers.lastRowReference = rowReference;
        }
    }

    if (!linkedToLithoTable) {
        // Row added. Rebuild rowReferences again to maintain order
        let temp = rowReferencesListPointers.firstRowReference;
        while (temp) {
            rowReferenceObject[temp.id] = temp;
            temp = temp.nextItem;
        }
    }

    return rowReferenceObject;
};

const createCellDataCollectionFromRowReference = (valuesCollection: any, errorsCollection: any) =>
    Object.entries(valuesCollection ?? {}).reduce(
        (accum, [key, value]) => ({
            ...accum,
            [key]: new CellData(value, errorsCollection?.[key] ?? []),
        }),
        {},
    );

export const addIntervalIfValid = (
    intervalTree: IntervalTree | null,
    rowReference: RowReference,
    useRowReferenceAsValue = false,
) => {
    // The from is generated by adding EPSILON_MULTIPLIER * Number.EPSILON to the value
    // The to is generated by subtracting EPSILON_MULTIPLIER * Number.EPSILON from the value
    // This ensures that the intervals don't overlap for the same start and end
    if (!intervalTree) return;
    const fromValue = rowReference.values.from;
    const toValue = rowReference.values.to;
    if (
        !rowReference.isAddedToIntervalTree &&
        fromValue !== null &&
        fromValue !== undefined &&
        toValue !== null &&
        toValue !== undefined &&
        fromValue < toValue
    ) {
        intervalTree?.insert(
            [
                fromValue + EPSILON_MULTIPLIER * Number.EPSILON,
                toValue - EPSILON_MULTIPLIER * Number.EPSILON,
            ],
            useRowReferenceAsValue ? rowReference : rowReference.id,
        );

        rowReference.isAddedToIntervalTree = true;
    }
};

export const removeIntervalIfPresent = (
    intervalTree: IntervalTree | null,
    rowReference: RowReference | null | undefined,
) => {
    if (!intervalTree || !rowReference) return;
    if (rowReference.isAddedToIntervalTree) {
        const fromValue = rowReference.values.from as number;
        const toValue = rowReference.values.to as number;
        intervalTree?.remove(
            [
                fromValue + EPSILON_MULTIPLIER * Number.EPSILON,
                toValue - EPSILON_MULTIPLIER * Number.EPSILON,
            ],
            rowReference.id,
        );

        rowReference.isAddedToIntervalTree = false;
    }
};

const addOutOfOrder = (rows: any, isInterval: boolean) => {
    let maxValue = Number.MIN_SAFE_INTEGER;
    const outOfOrderKey = isInterval ? 'from' : 'depth';
    for (const row of Object.values(rows) as any) {
        const fromValue = row[outOfOrderKey]?.value;
        if (fromValue === 0 || (fromValue && !isNaN(fromValue))) {
            const numFrom = Number(fromValue);
            if (numFrom >= maxValue) {
                maxValue = numFrom;
            } else {
                row[OUT_OF_ORDER] = true;
            }
        }
    }
};

export const constructTableRows =
    (
        rowReferences: Record<string, RowReference>,
        drillHole: Drillhole,
        columnData: any[],
        tableView: TableView,
        rowReferencesIntervalTree: IntervalTree | null = null,
    ) =>
    (
        tables: Record<string, Table>,
        lists: Record<string, List>,
        samples: Record<string, Sample>,
        linkedToLithoTable: string,
        isXRF = false,
        isSample = false,
    ) => {
        const allowedColumnIds: string[] = tableView.singleTable
            ? columnData?.map((c: ColDef) => c.colId)
            : columnData?.map((c: any) => c.children?.map((ch: any) => ch.colId)).flat();

        const rows: Record<string, any> = {};
        const sampleRows: Record<string, string[]> = {};
        const intervalErrors = drillHole?.intervalErrors?.[tableView.id] ?? [];

        const modifiedIntervalErrors = {
            dupe: intervalErrors.dupe,
        };

        let rowNumber = 1;
        for (const id in rowReferences) {
            let row: any = {};
            const rowReference = rowReferences[id] ?? {};

            const sample = samples[rowReference.sample ?? rowReference.xrfSample ?? ''];
            const parentSample = sample?.parentSample;
            const parentSampleObject = samples[parentSample ?? ''];
            const parentSampleRow = parentSampleObject?.rowReference;
            if (
                tableView.intervalType === DrillHoleReferencesIntervalType.INTERVAL &&
                !(parentSampleRow in rowReferences) &&
                !isXRF
            ) {
                addIntervalIfValid(rowReferencesIntervalTree, rowReference);
            }

            if (tableView.intervalType !== DrillHoleReferencesIntervalType.DATA) {
                const isInterval =
                    tableView.intervalType === DrillHoleReferencesIntervalType.INTERVAL;
                const rowParentSample = rows[parentSampleRow];
                const modifiedValues = createCellDataCollectionFromRowReference(
                    isInterval
                        ? { from: rowReference.values?.from, to: rowReference.values?.to }
                        : { depth: rowReference.values?.depth },
                    rowReference.verrors?.values?.[0],
                );

                if (isXRF) {
                    row = modifiedValues;
                } else if (!parentSample) {
                    row = getCurrentValuesWithErrors(rowReference, modifiedValues, isInterval);
                    if (
                        tableView.intervalType === DrillHoleReferencesIntervalType.DEPTH &&
                        !tableView.allowDupes
                    ) {
                        row = validateDepthErrors(row, rowReference, modifiedIntervalErrors);
                    }
                } else {
                    row = {
                        ...(rowParentSample &&
                            createCellDataCollectionFromRowReference(
                                isInterval
                                    ? {
                                          from: rowParentSample.from?.value,
                                          to: rowParentSample.to?.value,
                                      }
                                    : { depth: rowParentSample.depth?.value },
                                rowReference.verrors?.values?.[0],
                            )),
                    };
                }

                if (isInterval) attachLengthToInterval(row);
            }

            row = {
                ...row,
                ...(!rowReference.id.includes('-') && {
                    [ROW_NUMBER]: { errors: [], value: rowNumber },
                }),
                id: rowReference.id,
                ...(rowReference.sample && {
                    sample: rowReference.sample,
                }),
                ...(sample && {
                    SAMPLE_STATUS: { errors: [], value: sample.status },
                    SAMPLE_TYPE_STATE: { errors: [], value: SAMPLE_TYPES.PARENT },
                }),
                ...(parentSampleRow && {
                    parentSampleRow,
                    PARENT_SAMPLE: {
                        value: isXRF
                            ? (parentSampleObject.name ?? '')
                            : rows[parentSampleRow]?.[SAMPLE_NUMBER_COL_ID]?.value,
                        errors: [],
                    },
                    SAMPLE_TYPE_STATE: { errors: [], value: SAMPLE_TYPES.DUPLICATE },
                }),
            };

            if (!rowReference.id.includes('-')) {
                rowNumber += 1;
            }

            for (const record of Object.values(rowReference.dataRecords)) {
                // Assign cell data whether a record exists or not.
                // For multiColumns we need to check the current table we're looking at to prevent overwriting
                if (!tables[record.table]) continue;
                const tableCols =
                    record.table === SAMPLE_RESULTS
                        ? { ...tables[record.table].columns, ...tables[RANKED_RESULTS].columns }
                        : { ...tables[record.table].columns };

                if (linkedToLithoTable && record.table === linkedToLithoTable) {
                    row = {
                        ...row,
                        'from-lithology': new CellData(record.values['from-lithology']),
                        'to-lithology': new CellData(record.values['to-lithology']),
                        ROW_SPAN: new CellData(record.values[ROW_SPAN]),
                        PARENT_ROW_SPAN: new CellData(record.values[PARENT_ROW_SPAN]),
                        PADDED_ROW_SPAN: new CellData(record.values[PADDED_ROW_SPAN]),
                        PARENT_PADDED_ROW_SPAN: new CellData(record.values[PARENT_PADDED_ROW_SPAN]),
                        ...(SKIP_LITHOLOGY_ROW in record.values && {
                            SKIP_LITHOLOGY_ROW: new CellData(record.values[SKIP_LITHOLOGY_ROW]),
                        }),
                        ...(BUFFER_PARENT_ID in record.values && {
                            BUFFER_PARENT_ID: new CellData(record.values[BUFFER_PARENT_ID]),
                        }),
                        ...(PARENT_SKIP_LITHOLOGY_ROW in record.values && {
                            PARENT_SKIP_LITHOLOGY_ROW: new CellData(
                                record.values[PARENT_SKIP_LITHOLOGY_ROW],
                            ),
                            ...(record.values[PARENT_SKIP_LITHOLOGY_ROW] &&
                                !record.values[BUFFER_PARENT_ID] &&
                                ((record.values[PADDED_ROW_SPAN] as number) ?? 0) > 1 && {
                                    'from-lithology': new CellData(
                                        record.values[LITHOLOGY_MIN_FROM],
                                    ),
                                    'to-lithology': new CellData(record.values[LITHOLOGY_MAX_TO]),
                                }),
                        }),
                    };
                }

                for (const [columnKey, column] of Object.entries(tableCols)) {
                    // Do not allow columns that are not anywhere in the columnData
                    if (
                        !column ||
                        !allowedColumnIds.includes(column.id) ||
                        (row[PARENT_SKIP_LITHOLOGY_ROW]?.value &&
                            (row[PADDED_ROW_SPAN]?.value ?? 0) > 1)
                    )
                        continue;
                    const dataValue = record.values[columnKey] ?? '';
                    const finalValue = new CellData();
                    finalValue.value = dataValue;
                    finalValue.errors = Array.from(
                        new Set(record.verrors?.values?.[0]?.[columnKey] ?? []),
                    );

                    if (columnKey === SAMPLE_TYPE_COL_ID) {
                        row = { ...row, sampleTable: record.table };
                    }

                    if (columnKey === SAMPLE_NUMBER_COL_ID) {
                        const errorMessage = 'Sample number must be unique';
                        const sampleNumber = finalValue?.value ?? '';

                        if (sampleNumber) {
                            let finalError: string[] = [];

                            if (!(sampleNumber in sampleRows)) {
                                sampleRows[sampleNumber] = [id];
                                finalError = []; // Clears previous duplication errors
                            } else if (sampleRows[sampleNumber].length === 1) {
                                // Update the column we already processed as well as the current one
                                sampleRows[sampleNumber].push(id);
                                const oldId = sampleRows[sampleNumber][0];
                                rows[oldId][SAMPLE_NUMBER_COL_ID].errors = [errorMessage];
                                finalError = [errorMessage];
                            } else {
                                // Update only the current column
                                sampleRows[sampleNumber].push(id);
                                finalError = [errorMessage];
                            }

                            if (!finalValue.errors.length) {
                                finalValue.errors = finalError;
                            }
                        }
                    }

                    if (columnKey === SAMPLE_TYPE_COL_ID && dataValue === SAMPLE_CONTROL_TYPE_ID) {
                        row = {
                            ...row,
                            ...(tableView.intervalType ===
                                DrillHoleReferencesIntervalType.INTERVAL && {
                                from: { errors: [], value: '--' },
                                to: { errors: [], value: '--' },
                            }),
                            ...(tableView.intervalType ===
                                DrillHoleReferencesIntervalType.DEPTH && {
                                depth: { errors: [], value: '--' },
                            }),
                            SAMPLE_TYPE_STATE: { errors: [], value: SAMPLE_TYPES.CONTROL },
                        };
                    }

                    if (column.type === DrillHoleReferencesColumnType.LIST) {
                        if (column.list && lists[column.list] && dataValue) {
                            finalValue.value = createListRow(
                                lists[column.list].rows[String(dataValue)],
                                lists[column.list].columns,
                                lists[column.list].primaryColumn,
                            );
                        }

                        if (
                            columnKey === SAMPLE_CONTROL_TYPE_COL_ID &&
                            !isXRF &&
                            record.values[SAMPLE_TYPE_COL_ID] === SAMPLE_CONTROL_TYPE_ID &&
                            !record.values[SAMPLE_CONTROL_TYPE_COL_ID]
                        ) {
                            finalValue.errors = ['Control Type is required'];
                        }
                    }

                    if (column.type === DrillHoleReferencesColumnType.NUMERIC) {
                        // Formatting numeric type
                        if (!(dataValue === null || dataValue === undefined || dataValue === '')) {
                            finalValue.value = Number(dataValue);
                        }
                    }

                    if (column.id === SAMPLE_TYPE_COL_ID && parentSampleRow && !isXRF) {
                        rows[parentSampleRow] = {
                            ...rows[parentSampleRow],
                            childSamples: {
                                ...(rows[parentSampleRow]?.childSamples ?? {}),
                                [id]: record.values[SAMPLE_TYPE_COL_ID],
                            },
                        };
                    }

                    row = {
                        ...row,
                        [column.id]: finalValue,
                    };
                }
            }
            row.index = rowReference.index;
            rows[id] = row;
        }

        const rowsBySampleType: Record<string, any> = {};
        if (isSample) {
            // For sample tables the interval and validation errors need to be generated
            // for rows having same sample type. If the sample types are different for two
            // intervals they'll not be in error even if they have same from/to. Similarly, gap
            // errors are only checked for data with same sample type. We break down the
            // data based on sample type and run validations for each sample type separately
            for (const id in rows) {
                const row = rows[id];
                if (row[SAMPLE_TYPE_COL_ID]?.value?.primary) {
                    if (!(row[SAMPLE_TYPE_COL_ID].value.primary in rowsBySampleType)) {
                        rowsBySampleType[row[SAMPLE_TYPE_COL_ID].value.primary] = {};
                    }
                    rowsBySampleType[row[SAMPLE_TYPE_COL_ID].value.primary][id] = row;
                } else {
                    if (!(emptySampleTypeID in rowsBySampleType)) {
                        rowsBySampleType[emptySampleTypeID] = {};
                    }
                    rowsBySampleType[emptySampleTypeID][id] = row;
                }
            }
        }

        // Validate Interval Errors
        if (tableView.intervalType === DrillHoleReferencesIntervalType.INTERVAL && !isXRF) {
            if (isSample) {
                if (!tableView.allowOverlaps) {
                    for (const key in rowsBySampleType) {
                        const sampleTypeRows = rowsBySampleType[key];
                        validateIntervalErrorsFast(
                            sampleTypeRows,
                            rowReferencesIntervalTree,
                            tableView.allowDupes,
                            true,
                        );
                    }
                }
                if (!tableView.allowGaps) {
                    for (const key in rowsBySampleType) {
                        const sampleTypeRows = rowsBySampleType[key];
                        validateGapErrorsFast(sampleTypeRows, rowReferencesIntervalTree, isSample);
                    }
                }
            } else {
                // Normal table performs validations on all data at once
                if (!tableView.allowOverlaps) {
                    validateIntervalErrorsFast(
                        rows,
                        rowReferencesIntervalTree,
                        tableView.allowDupes,
                    );
                }
                if (!tableView.allowGaps) {
                    validateGapErrorsFast(rows, rowReferencesIntervalTree, isSample);
                }
            }
        }

        if (
            tableView.intervalType === DrillHoleReferencesIntervalType.INTERVAL ||
            tableView.intervalType === DrillHoleReferencesIntervalType.DEPTH
        ) {
            // Adds row out of order indicator to rows which would enable the sort rows action
            if (isSample) {
                for (const sampleTypeRows of Object.values(rowsBySampleType)) {
                    addOutOfOrder(
                        sampleTypeRows,
                        tableView.intervalType === DrillHoleReferencesIntervalType.INTERVAL,
                    );
                }
            } else {
                addOutOfOrder(
                    rows,
                    tableView.intervalType === DrillHoleReferencesIntervalType.INTERVAL,
                );
            }
        }

        return Object.values(rows);
    };

export const getSampleTypeFromRowReference = (rowReference: RowReference | undefined) => {
    const SAMPLE_TYPE_COLUMN = 'b9d3982a0730473a8d32dcc5';

    if (!rowReference) {
        return null;
    }
    for (const recordKey in rowReference.dataRecords) {
        const dataRecord = rowReference.dataRecords[recordKey];
        if (!dataRecord?.values) {
            continue;
        }

        const sampleType = dataRecord.values[SAMPLE_TYPE_COLUMN];
        if (sampleType) {
            return sampleType;
        }
    }
    return null;
};

export const DEFAULT_COLLAR_SORT_KEY = '-_created_at';

export const cacheSortOptions = (
    userId: string,
    subscriptionId: string,
    type: ACTIVITY_TYPES,
    projId: string,
    sortKey?: string,
    sortDirection?: Order,
) => {
    const allForUser = store.get(`${CACHED_SORT_OPTIONS_KEY}_${userId}_${subscriptionId}`, {});
    const optionsForType = allForUser[type] ?? {};
    let keyToSave = '';
    if (!!sortKey && !!sortDirection) {
        keyToSave = sortDirection === Order.DESCENDING ? '-' : '';
        keyToSave = `${keyToSave}${sortKey}`;
    }
    const updated = {
        ...allForUser,
        [type]: { ...optionsForType, [projId]: keyToSave },
    };
    store.set(`${CACHED_SORT_OPTIONS_KEY}_${userId}_${subscriptionId}`, { ...updated });
};

export const getSortOptionsFromCache = (
    userId: string,
    subscriptionId: string,
    type: ACTIVITY_TYPES,
    projId: string,
) => {
    const allForUser = store.get(`${CACHED_SORT_OPTIONS_KEY}_${userId}_${subscriptionId}`, {});
    if (!!allForUser && allForUser[type] && !!projId) {
        return allForUser[type][projId] ?? '';
    }
    return '';
};

export const getSortOptionsFromCacheWithDefault = (
    userId: string,
    subscriptionId: string,
    type: ACTIVITY_TYPES,
    projId: string,
) => getSortOptionsFromCache(userId, subscriptionId, type, projId) || DEFAULT_COLLAR_SORT_KEY;
